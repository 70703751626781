.institution {
  --institution-background: #f7f7f7;
}

.iconButton {
  --icon-button-color: rgb(64, 64, 64);
}

@media (prefers-color-scheme: dark) {
  .institution {
    --institution-background: #333;
  }
  
  .iconButton {
    --icon-button-color: rgb(172, 172, 172);
  }
}

.institution-card {
  border: thin solid black;
  border-radius: 8px;
  margin: 4px;
  margin-bottom: 8px;
  overflow: hidden;
  // box-shadow: 3px 4px 4px 0px rgba(0, 0, 0, 25%);
}

.institution {
  display: grid;
  grid-template-columns: 1fr repeat(3, auto max-content);
  white-space: nowrap;
  padding-left: 4px;
  background-color: var(--institution-background);
  border-bottom: thin black solid;
  border-radius: 8px 8px 0px 0px;
}

.institution-name {
  text-overflow: ellipsis;
  overflow: hidden;
  font-size: larger;
}

.separator {
  border-top: thin black solid;
}

.iconButton {
  color: var(--icon-button-color);
}
