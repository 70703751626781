.layout {
  display: grid;
  grid-template-rows: max-content minmax(0, 1fr);
  grid-template-columns: minmax(0, 1fr);
}

.form {
  margin: 0.5rem;
}

.form > * {
  margin-right: 0.5rem;
}