.main {
  display: grid;
  grid-template-columns: minmax(0, 1fr);
  grid-template-rows: max-content minmax(0, 1fr);
  height: 100%;
  width: 100%;
}

.side-bar {
  grid-area: sidebar;
}
