.layout {
  margin-top: 0.5rem;
  margin-bottom: 1rem;
  margin-left: 2rem;
  margin-right: 2rem;

  label {
    display: flex;
    flex-direction: row;
    white-space: nowrap;

    >div {
      padding-left: 1rem;
      overflow-x: hidden;
    }
  }
}

.transactions-wrapper {
  display: grid;
  row-gap: 1rem;
  border: thin solid white;
  height: max-content;
  max-height: 15rem;
  min-height: 3rem;
  overflow-y: auto;
  padding-left: 0.35rem;
  overscroll-behavior: contain;
}

.section {
  margin-top: 1rem;
  margin-bottom: 0.25rem;
}

.account {
}
