@use "./breakpoints";

.transaction-split-item {
  display: grid;
  grid-template-columns: minmax(0, 1fr) repeat(2, 28px);
  align-items: center;
  column-gap: 0.25rem;

  >:first-child {
    display: grid;
    grid-template-columns: minmax(0, 12rem) minmax(0, 8rem) minmax(0, 1fr);
    grid-template-areas: "category amount comment";
    grid-template-rows: max-content;

    >:nth-child(1) {
      grid-area: category;
    }

    >:nth-child(2) {
      grid-area: amount;
    }

    >:nth-child(3) {
      grid-area: comment;
    }
  }

  >input {
    border: solid 1px gray;
    border-radius: 4px;
    margin: 1px;
    padding: 1px;
  }
  
  >label {
    text-align: right;
    margin-bottom: 0;
    padding-right: 2px;
  }
}

@media (max-width: breakpoints.$side-by-side) {
  .transaction-split-item>:first-child {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    grid-template-areas: "category amount"
        "comment comment";
    grid-template-rows: repeat(2, max-content);
  }
}

.comment {
  border-radius: 4px;
  border: thin solid gray;
  margin: 1px;
}
