.report-item {
  display: grid;
  grid-template-columns: minmax(0, 4fr) repeat(2, minmax(0, 2fr)) minmax(0, 1fr) max-content;
  grid-template-rows: max-content;
}

.report-item * {
  padding-left: 0.25rem;
  padding-right: 0.25rem;
}
