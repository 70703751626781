.form {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 33rem;
  justify-self: center;
  border: thin black solid;
  padding: 2rem;
  border-radius: 0.5rem;
}

.frame {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  align-items: center;
}

.medium .title,
.small .title,
.title {
  font-size: 4rem;
  font-family: Georgiate, sans-serif;
  color: #8e2727;
  margin-top: 1rem;
  margin-bottom: 2rem;
  cursor: pointer;
}

.tiny .title {
  font-size: 3rem;
}

.subtitle {
  font-size: 2rem;
}

.description {
  font-size: 1.25rem;
}

.tiny .subtitle {
  font-size: 1.5rem;
}

.button {
  margin: 1rem 2rem;
  font-size: 1.5rem;
}

.status {
  margin-bottom: 1rem;
}