.delete {
  margin-top: 1rem
}

.enable-notifications {
  margin-top: 1rem
}

.icon {
  color: red;
}