@use "../breakpoints";

.layout {
  display: grid;
  grid-template-columns: minmax(0, 1fr) max-content;
  grid-template-rows: minmax(0, 1fr);

  label {
    display: flex;
    column-gap: 0.25rem;
    margin-left: 0.25rem;
  }

  .controls {
    display: flex;
    flex-direction: column;
    row-gap: 0.25rem;
  }
}

@media (max-width: breakpoints.$side-by-side) {
  .layout {
    grid-template-columns: minmax(0, 1fr);
    grid-template-rows: minmax(0, 1fr) max-content;
    font-size: 1rem;
    border: thin white solid;
  
    .controls {
      flex-direction: row;
      column-gap: 0.25rem;
    }
  }  
}