@use "./breakpoints";
@use "./variables";

html {
  overscroll-behavior-y: none;
}

@media (prefers-color-scheme: dark) {
  .modal-content {
    background-color: #333;
  }  
}

body {
  color: var(--text-color);
  background-color: var(--page-color);
  height: 100vh;
  width: 100vw;
}

.app {
  display: grid;
  grid-template-columns: minmax(0, 1fr);
  grid-template-rows: minmax(0, 1fr);
  height: 100%;
}

.window {
  margin-right: 1rem;
  margin-bottom: 1rem;
  border-radius: 0.5rem;
  overflow: hidden;
  position: relative;
  background-color: var(--page-color);
  color: var(--text-color);
  z-index: 4;
}

@media (max-width: breakpoints.$side-by-side) {
  .window {
    margin-right: unset;
    margin-bottom: unset;
  }
}

.navbar {
  background-color: #228B22;
}

.navbar-nav .nav-link {
  color: white;
}

.drop-down {
  position: fixed;
  color: var(--text-color);
  background-color: var(--page-color);
  border: black thin solid;
  border-radius: 4px;
  overflow-x: hidden;
  overflow-y: auto;
  overscroll-behavior-y: contain;
  overscroll-behavior-x: none;
  z-index: 4000;
}

.side-bar {
  grid-area: sidebar;
}

.tools {
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  grid-template-rows: minmax(0, 1fr);
  width: 100%;
  height: 100%;
}

#addCategoryDialog .modal-footer {
  display: grid;
  grid-template-columns: max-content 1fr repeat(2, max-content);
}

.accounts {
  display: grid;
  height: 100%;
  grid-template-rows: max-content 1fr;
  user-select: none;
  -webkit-user-select: none;
}

.account-select-item {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  grid-template-areas: "acct-name acct-name track-selection" "acct-type acct-balance track-selection";
  border: solid thin gray;
  border-radius: 4px;
  margin-bottom: 8px;
  box-shadow: 0 2px 7px 1px rgba(0, 0, 0, 0.3);
  background-color: whitesmoke;
}

.account-select-item .account-name {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  grid-area: acct-name;
  padding-left: 4px;
  padding-top: 4px;
}

.account-select-item .account-type {
  display: grid;
  grid-template-columns: max-content max-content;
  grid-area: acct-type;
  font-size: 14px;
  padding-left: 4px;
}

.account-select-item .account-type>label {
  margin-right: 8px;
}

.account-select-item .account-balance {
  display: grid;
  grid-template-columns: max-content max-content;
  grid-area: acct-balance;
  justify-content: space-between;
  font-size: 14px;
}

.account-select-item .track-selection {
  grid-area: track-selection;
  background-color: white;
  border-left: solid thin gray;
  border-radius: 0px 4px 4px 0px;
  display: grid;
  grid-template-columns: 1fr;
  justify-items: center;
  padding: 4px;
}

.account-select-item .track-selection label {
  margin: 0;
}

.account-bar {
  background-color: gray;
  color: white;
  padding-left: 0.25rem;
}

.add-group {
  margin-left: 14px;
  color: rgba(255, 255, 255, 0.7);
}

.add-group:hover {
  color: white;
  cursor: pointer;
}

.add-acct {
  margin-left: 14px;
  color: rgba(255, 255, 255, 0.7);
}

.add-acct:hover {
  color: white;
}

.register {
  display: grid;
  grid-template-rows: repeat(2, max-content) 1fr;
  user-select: none;
  -webkit-user-select: none;
}

@media (max-width: breakpoints.$side-by-side) {
  .register {
    grid-template-rows: max-content 1fr;
    height: 100%;
  }
}

.trans-cat-edit {
  display: grid;
  grid-template-columns: minmax(0, 1fr) max-content;
}

.currency {
  text-align: right;
  margin-right: 14px;
}

.negative {
  color: red;
}

.group-element-bar {
  display: grid;
  grid-template-columns: max-content max-content max-content;
  align-items: center;
}

.cat-element-bar {
  display: grid;
  grid-template-columns: max-content minmax(0, 1fr);
  align-items: center;
  overflow: hidden;

  &.system {
    grid-template-columns: max-content minmax(0, 1fr);
  }  
}

.cat-list-name {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.cat-list-amt {
  min-width: 4.25rem;
}

.cat-list-cat {
  display: grid;
  grid-template-columns: minmax(0, 1fr) max-content;
  justify-content: space-between;
  user-select: none;
  -webkit-user-select: none;
  cursor: pointer;

  &.system {
    margin-left: 0px;
  }
}

@media (min-width: breakpoints.$side-by-side) {
  .cat-list-cat {
    &:not(.selected):hover {
      background-color: var(--hover-background);
      color: var(--hover-color);
    }
  
    &.selected {
      background-color: var(--selection-background);
      color: var(--selection-color);
    }
  }
}

.cat-list-item>.cat-list-cat,
.cat-list-group>.cat-list-cat,
.cat-list-item>.cat-list-item,
.cat-list-group>.cat-list-group
 {
  margin-left: 28px;
}

.fund-container {
  display: grid;
  height: 100%;
}

.cat-list-group div.fund-list-item:nth-child(odd) {
  background-color: whitesmoke;
}

.unassigned-cat {
  font-style: oblique;
  color: rgb(156, 156, 156);
}

#categoryDropDownList {
  height: 250px;
  width: max-content;
  background-color: white;
  position: absolute;
  overflow-y: scroll;
  border: black solid thin;
  box-shadow: 0 2px 7px 1px rgba(0, 0, 0, 0.3);
  z-index: 2000;
  user-select: none;
  -webkit-user-select: none;
}

.category-list-item {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.category {
  padding: 1px
}

.category-input {
  border: solid gray thin;
  border-radius: 4px;
  margin: 1px;
  padding: 1px;
  padding-left: 4px;
}

.category-input::placeholder {
  font-style: oblique;
  color: red;
}

.category-select-item:not(.selected):hover {
  background-color: var(--hover-background);
}

.category-select-item.selected {
  background-color: var(--selection-background);
}

.cat-fund-table {
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  margin-top: 0.5rem;
}

.cat-fund-items {
  height: 100%;
  overflow-y: scroll;
}

.cat-fund-item {
  display: grid;
  grid-template-columns: 2fr 2fr minmax(0, 1fr);
}

.fund-cats-wrapper {
  position: relative;
  overflow: hidden;
}

#addFundingForm {
  height: 100%;
  display: grid;
  grid-template-rows: max-content minmax(0, 1fr);
}

#addFundingDialog .modal-body {
  overflow-y: hidden;
}

.dollar-amount {
  text-align: right;
}

.funding-header {
  display: flex;
  flex-flow: row;
  justify-content: space-around;
}

.splits-total {
  display: inline-block;
  width: 100%;
  border: solid thin gray;
  padding-right: 2px;
  justify-self: right;
}

.funding-total {
  display: inline-block;
  width: 150px;
  border: solid thin gray;
  padding-right: 8px;
}

.transaction-split-items {
  display: grid;
  grid-template-rows: max-content;
}

.transaction-split-item {
  display: grid;
  grid-template-columns: repeat(4, minmax(0, 1fr)) repeat(2, 28px);
  align-items: center;
}

.transaction-split-item.no-balances {
  grid-template-columns: minmax(0, 12rem) minmax(0, 8rem) minmax(0, 1fr) repeat(2, 28px);
  column-gap: 0.25rem;
}

.transaction-split-item>input {
  border: solid 1px gray;
  border-radius: 4px;
  margin: 1px;
  padding: 1px;
}

.transaction-split-item>label {
  text-align: right;
  margin-bottom: 0;
  padding-right: 2px;
}

.split-button {
  border: gray solid 1px;
  border-radius: 4px;
  background-color: white;
  margin: 1px;
  padding: 1px;
}

.add-split-button {
  justify-self: center;
}

.alert {
  padding: 15px;
  margin-top: 20px;
  margin-bottom: 20px;
  border: 1px solid transparent;
  border-radius: 4px;
}

.alert-danger {
  color: #a94442;
  background-color: #f2dede;
  border-color: #ebccd1;
}

.alert-danger a {
  color: #a94442;
  font-weight: bold;
}

.rebalance-container {
  display: grid;
  height: 100%;
}

.rebalance-header {
  display: flex;
  justify-content: space-around;
  border-bottom: thin gray solid;
  padding-bottom: 0.5rem;
}

.rebalance-unassigned {
  display: inline-block;
  width: 100px;
  margin-right: 14px;
}

.cat-rebalance-container {
  overflow-y: auto;
}

.cat-rebalance-item {
  display: grid;
  grid-template-columns: repeat(4, minmax(0, 1fr));
  grid-gap: 14px;
}

.cat-rebalance-item div:first-child {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.cat-rebalance-group .cat-rebalance-group,
.cat-rebalance-group .cat-rebalance-item>div:first-child {
  padding-left: 28px;
}

.scrollable-form {
  display: grid;
  height: 100%;
  grid-template-rows: max-content minmax(0, 1fr) max-content
}

.status-item {
  display: grid;
  grid-template-columns: 1.5fr repeat(5, minmax(0, 1fr));
}

.chart-wrapper {
  background-color: #ffffffff;
  border-radius: 0.5rem;
  overflow: clip;
  position: relative;
}

.plan-tools {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
}

.plan-item {
  display: grid;
  grid-template-columns: max-content max-content;
  align-items: center;
}

.plan {
  display: grid;
  grid-template-rows: repeat(3, minmax(0, max-content));
  border-radius: 0.5rem;
  overflow: clip;
  position: relative;
}

.plan-detail-wrapper {
  display: grid;
  grid-template-columns: max-content max-content;
  overflow-y: auto;
  overflow-x: hidden;
  height: 100%;
}

.plan-details {
  padding-left: 14px;
  /* overflow-y: auto; */
  /* overflow-x: hidden; */
  display: flex;
  flex-direction: column;
  width: max-content;
}

.plan-history {
  display: grid;
  grid-template-columns: repeat(13, 100px);
  overflow-x: auto;
  height: 30px;
}

.title .plan-history {
  text-align: center;
  height: 100%;
}

.plan-total.title {
  overflow-x: hidden;
  width: max-content;
}

.plan-total {
  display: grid;
  grid-template-columns: 200px 125px 100px 1fr;
  padding-left: 14px;
  background-color: var(--page-color);
  border-top: thin solid black;
  overflow: auto;
}

.plan-title-wrapper {
  overflow-y: hidden;
  overflow-x: hidden;
}

.plan-wrapper {
  /* width: 100%; */
  /* height: 100%; */
  /* display: grid; */
  overflow-y: scroll;
  overflow-x: hidden;
}

.title {
  color: white;
  background-color: gray;
}

.title div {
  padding-left: 0.25rem;
  padding-right: 0.25rem;
  user-select: none;
  -webkit-user-select: none;
}

.title div:not(:first-child) {
  border-left: solid thin black
}

.unassigned-label {
  justify-self: right;
}

/* .selected {
  background-color: lightblue;
} */

.item-title {
  padding-left: 2px;
}

.item-title-amount {
  text-align: right;
  padding-right: 2px;
}

.plan-list {
  height: 100%;
  width: 100%;
  user-select: none;
  -webkit-user-select: none;
}

.plan-list * {
  padding-top: 0.25rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  padding-bottom: 0.25rem;
}

.payee-report {
  padding: 1rem;
  display: grid;
  grid-template-rows: max-content minmax(0, 1fr);
  grid-template-columns: minmax(0, 1fr);
  /* justify-content: center; */
}

.payee-report-controls {
  display: flex;
  column-gap: 1rem;
  flex-flow: row;
  align-items: flex-end;
  padding-bottom: 1rem;
}

.ellipsis {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.transaction-form {
  opacity: 0;
  transition: opacity 0.5s ease-out;
  background-color: #fff;
}

.transaction-form.open, .open>.transaction-form {
  padding: 1rem;
  border: black thin solid;
  margin: 0 1rem 1rem 1rem;
  opacity: 1;
  border-radius: 0 0 0.5rem 0.5rem;
  box-shadow: 3px 3px 3px 3px rgba(0, 0, 0, 0.3);
  background: linear-gradient(178deg, lightblue, transparent);
  border-top: none;
  z-index: 1;
}