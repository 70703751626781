.form {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 33rem;
  justify-self: center;
  border: thin black solid;
  padding: 2rem;
  border-radius: 0.5rem;
}

.tiny .form {
  padding: 1rem;
}

.frame {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  align-items: center;
}

.title {
  font-size: 4rem;
  font-family: Georgiate, sans-serif;
  color: #8e2727;
  margin-top: 1rem;
  margin-bottom: 2rem;
  cursor: pointer;
}

.tiny .title {
  font-size: 3rem;
}

.tiny .title,
.small .title {
  margin-top: 0;
  margin-bottom: 0;
}

.subtitle {
  font-size: 2rem;
}

.description {
  font-size: 1.25rem;
}

.tiny .subtitle {
  font-size: 1.3rem;
}

.small .subtitle {
  font-size: 1.7rem;
}

.button {
  margin: 1rem 2rem;
  font-size: 1.5rem;
}

.tiny .button {
  font-size: 1.2rem;
}

.fine-print {
  font-size: 0.85rem;
}
