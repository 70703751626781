.wrapper {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 100;
}

.items {
  display: flex;
  flex-direction: column;
  color: black;
  background-color: white;
  width: max-content;
  padding: 0.25rem 0.75rem;
  row-gap: 0.25rem;
  font-size: x-large;
  right: 0;
  position: absolute;
  border-radius: 8px;
}
