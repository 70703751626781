.table-wrapper {
  overflow-x: auto;
  height: 100%;
}

.table {
  min-width: 500px;
  height: 100%;
  display: flex;
  flex-direction: column;
}
