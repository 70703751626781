@use "../breakpoints";

.pending-register-title {
  padding-left: 0.75rem;
}

.pending {
  display: grid;
  grid-template-rows: max-content minmax(0, 1fr);
  user-select: none;
  -webkit-user-select: none;
  height: 100%;
}

@media (max-width: breakpoints.$side-by-side) {
  .pending {
    grid-template-rows: 1fr;
  }
}
