@use "../breakpoints";

.layout {
  display: grid;
  grid-template-columns: repeat(2, max-content);
  column-gap: 1rem;

  @media (max-width: breakpoints.$side-by-side) {
    grid-template-columns: minmax(0, 1fr);
    grid-template-rows: max-content max-content;
    column-gap: unset;
  }
}

.layout2 {
  display: grid;
  grid-template-columns: repeat(3, max-content);
  column-gap: 1rem;
}

.message {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.response-wrapper {
  width: 100%;
  padding-top: 0.5rem;
  padding-bottom: 1rem;
  padding-left: 2rem;
  padding-right: 2rem;
}

.response {
  width: 100%;
  height: max-content;
  background-color: black;
  color: white;
  white-space: pre-wrap;
}
