@use "../breakpoints";

.layout {
  height: 100%;
  overflow-y: auto;
}

.logs {
  display: flex;
  flex-direction: column;
  @media (max-width: breakpoints.$side-by-side) {
    row-gap: 0.5rem;
  }
}
