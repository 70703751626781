.accounts {
  display: grid;
  height: 100%;
  grid-template-rows: max-content 1fr;
  user-select: none;
  -webkit-user-select: none;
  background-color: var(--page-color);
  overflow-y: hidden;
}

.theme {
  background-color: #2e5ea8;
}

.accounts :global(.tab-content) {
  overflow-y: auto;
}