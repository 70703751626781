@use "../breakpoints";

.main {
  display: grid;
  grid-template-columns: minmax(0,3fr) minmax(0,1fr);
  grid-template-rows: minmax(0,1fr);
}

@media (max-width: breakpoints.$side-by-side) {
  .main {
    grid-template-columns: minmax(0,1fr);
    grid-template-rows: minmax(0,3fr) minmax(0,1fr);
  }
}

/* .main :global(.window) {
  padding: 1rem;
} */

.list {
  overflow-y: auto;
  height: 100%;
  padding-right: 1rem;
  padding-left: 1rem;
  padding-top: 0.5rem;
}