.search-strings {
  display: grid;
  row-gap: 0.25rem;
  margin-top: 0.5rem;
}

.search-string-layout {
  display: grid;
  grid-template-columns: minmax(0, 1fr) max-content max-content;
  column-gap: 0.5rem;
  align-items: center;
}
