.radio-button {
  margin-top: 1rem;
}

.radio-button>label {
  user-select: none;
}

.radio-button>label>input {
  margin-right: 0.5rem;
}

.radio-button>*:not(:first-child) {
  margin-left: 1.3rem;
}

.mask {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(255,255,255,0.5);
}

.children-wrapper {
  position: relative;
}

.not-selected {
  pointer-events: none;
  user-select: none;
}
