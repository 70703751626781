.report {
  display: grid;
  grid-template-rows: max-content;
}

.report-items {
  overflow: auto;
  height: 100%;
  width: 100%;
}

.row {
  display: grid;
  grid-template-columns: repeat(13, max-content);
  grid-template-rows: max-content;
}

.totals {
  border-top: thin black solid;
  padding-top: 0.25rem;
}

.indent1 {
  padding-left: 0.5rem;
}

.indent2 {
  padding-left: 1.5rem;
}

.category {
  width: 10rem;
}

.historyItem {
  width: 5rem;
  margin-left: 0.25rem;
  margin-bottom: 0.25rem;
}

.change {
  background-color: yellow;
}
