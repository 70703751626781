.outerWrapper {
  display: grid;
  user-select: none;
  -webkit-user-select: none;
  grid-template-rows: max-content minmax(0, 1fr);
  grid-template-columns: minmax(0, 1fr);
  padding: 0 0.25rem 0 0.25rem;
}

.title {
  font-size: 2rem;
  font-weight: bold;
  transition: font-size 0.3s;
  transition: font-weight 0.3s;

  &:global(.open) {
    font-size: 1.25rem;
    font-weight: normal;
  }  
}

.title-wrapper {
  display: flex;
}

.back-button {
  font-size: 1.25rem;
  width: 0;
  overflow: hidden;
  transition: width 0.3s;

  &:global(.open) {
    width: 1rem;
  }  
}

.wrapper {
  position: relative;
  font-size: 1.25rem;
  overflow: hidden;
}

.off-canvas {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: var(--page-color);
  color: var(--text-color);
  transform: translate(100%);
  transition: transform 0.3s ease;
  
  display: grid;
  grid-template-columns: minmax(0, 1fr);
  grid-template-rows: minmax(0, 1fr);
  grid-template-areas: "title" ".";

  &:global(.open) {
    transform: translate(0%);
  }  
}
