.link-button {
  border: 2px solid black;
  text-decoration: none;
  cursor: pointer;
  background-color: #0b5ed7;
  color: white;
  padding: 0 1rem;
  border-radius: 0.4rem;
  font-size: 1.25rem;
}

.button-tray {
  display: grid;
  grid-template-columns: repeat(2, max-content);
  grid-template-rows: max-content;
  column-gap: 2rem;
  margin-top: 1rem;
  justify-content: center;
}

.frame {
  display: grid;
  grid-template-columns: minmax(0,1fr);
  grid-template-rows: max-content minmax(0,1fr);
  width: 100%;
  height: 100%;
  max-width: 1400px;
  justify-self: center;
}

.content {
  display: grid;
  grid-template-columns: minmax(0,1.2fr) minmax(0,2fr);
  grid-template-rows: minmax(0,1fr);
  width: 100%;
  height: 100%;
  align-items: start;
  justify-items: center;
}

.medium .content {
  grid-template-columns: minmax(0,1.2fr) minmax(0,1fr);
}

.tiny .content,
.small .content {
  grid-template-columns: minmax(0,1fr);
}

.intro-text {
  margin-left: 2rem;
  display: flex;
  flex-direction: column;
  align-items: start;
}

.tiny .intro-text,
.small .intro-text,
.medium .intro-text {
  margin-left: 1rem;
  margin-right: 1rem;
}

.title {
  font-size: 4.5rem;
  font-family: Georgiate, sans-serif;
  color: #8e2727;
}

.tiny .title {
  font-size: 3rem;
}

.small .title,
.medium .title {
  font-size: 4rem;
}

.signup-free-button {
  font-size: 2rem;
  padding: 0.5rem 1rem;
  background-color: #308730;
  color: white;
}  

.tiny .signup-free-button {
  font-size: 1.9rem;
}

.tiny .signup-free-button,
.small .signup-free-button,
.medium .signup-free-button {
  align-self: center;
}

.image {
  max-width: 90%;
  max-height: 90%;
  align-self: start;
}

.tiny .image,
.small .image {
  display: none;
}

.catch-phrase {
  font-size: 2rem;
}

.sub-catch-phrase {
  margin-top: 1rem;
  margin-bottom: 2rem;
}
