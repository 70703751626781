@use "../variables";
@use "../breakpoints";

.layout {
  display: grid;
  grid-template-columns: minmax(0, 0.15fr) minmax(0, 0.85fr);
  grid-template-rows: minmax(0, 1fr) max-content;
  grid-template-areas: "statements register" "statements totals";
  height: 100%;
}

.statements {
  grid-area: statements;
}

.transactions {
  grid-area: register;
  width: 100%;
  height: 100%;
}

.statement {
  grid-area: totals;
  min-height: 4rem;
  padding: 0.25rem;
}

.items {
  user-select: none;
  -webkit-user-select: none;
  cursor: pointer;
  padding-left: 0.25rem;

  & > div.selected {
    color: var(--selection-color);
    background-color: var(--selection-background);
  }

  & >div:not(.selected):hover {
    background-color: var(--hover-background);
    color: var(--hover-color);
  }
}

@media (max-width: breakpoints.$side-by-side) {
  .layout {
    grid-template-columns: minmax(0, 0.25fr) minmax(0, 0.85fr);
  }

  .items {
    font-size: 14px;
  }
}
